.grid-menu{
    justify-content: center;
    align-content: center;
    align-items: center;
    .col{
      max-width: fit-content;
      margin-left: auto;
      margin-right: auto;
    }
    .hero {
        display: inline-block;
        position: relative;
        width: 200px;
        min-width: 200px;
        height: 200px;
        border-radius: 20px;
        overflow:hidden;
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.3);
        /*margin: 30px;*/
      }
      
      .image {
        height: 100%;
      }
      
      .text {
        background-image: linear-gradient(0deg , #3f5efb, #fc466b);
        border-radius: 30px;
        position: absolute;
        top: 55%;
        left: -5px;
        height: 65%;
        width: 108%;
        transform: skew(19deg, -9deg);
      }
      
      .second .text {
        background-image: linear-gradient(-20deg , #bb7413, #e7d25c)
      }
      
      .logo {
        height: 80px;
        width: 80px;
        border-radius: 20px;
        background-color: #fff;
        position: absolute;
        bottom: 30%;
        left: 30px;
        overflow:hidden;
        box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.7);
      }
      
      .logo img {
        height: 100%;
      }
      
      .main-text {
        position: absolute;
        color: #fff;
        font-weight: 900;
        left: 150px;
        bottom: 26%;
      }
      
      .hero-btn {
        position: absolute;
        color: #fff;
        right: 30px;
        bottom: 10%;
        padding: 10px 20px;
        border: 1px solid #fff;
        animation: button-anim 4s ease 0s infinite normal forwards;
      }
      
      @keyframes button-anim {
        0% {
          transform: translate(0);
        }
      
        20% {
          transform: translate(-1px, 1px);
        }
      
        40% {
          transform: translate(-1px, -1px);
        }
      
        60% {
          transform: translate(1px, 1px);
        }
      
        80% {
          transform: translate(1px, -1px);
        }
      
        100% {
          transform: translate(0);
        }
      }
      
      .hero-btn:hover{
        animation: none;
      }
      
      .hero-btn a {
        color: #fff;
      }
      
      .date {
        position: absolute;
        color: #fff;
        left: 30px;
        bottom: 10%;
      }
      
      .btn i:before {
        width: 14px;
        height: 14px;
        position: fixed;
        color: #fff;
        background: #0077B5;
        padding: 10px;
        border-radius: 50%;
        top:5px;
        right:5px;
      }
}
